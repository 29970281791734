import React, { useState, memo } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material";

import Section from "../layout/utils/Section";
import RichTextRenderer from "../rich-text-renderer/RichTextRenderer";
import { IFaqsItemProps, IFaqsProps } from "./types";

const FaqsItem: React.FC<IFaqsItemProps> = ({
  question,
  answer,
  idx,
  accordionProps,
  questionTypographyProps,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        disableGutters
        sx={{
          boxShadow: "none",
          borderBottom: "1px solid #f8f8f8",
          bgcolor: expanded ? "#F1FAF5" : undefined,
          borderRadius: expanded ? theme.spacing(5) : undefined,
          ...(accordionProps?.sx || {}),
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls={`faq-${idx}`}
          id={question}
          sx={{
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            minHeight: "0 !important",
          }}
        >
          <Typography
            {...questionTypographyProps}
            variant="h5"
            sx={{
              [theme.breakpoints.down("sm")]: {
                fontSize: 14,
              },
              ...(questionTypographyProps?.sx || {}),
            }}
          >
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pb: theme.spacing(6) }}>
          <RichTextRenderer richTextHtml={answer} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const Faqs: React.FC<IFaqsProps> = ({
  faqs,
  containerProps,
  typographyProps,
  accordionProps,
  questionTypographyProps,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Section
      boxProps={{ py: { xs: 6, md: 12 } }}
      containerProps={{ maxWidth: "md" }}
      {...props}
    >
      <>
        <Typography
          {...(typographyProps || {})}
          variant="h3"
          align="center"
          sx={{
            fontWeight: 700,
            [theme.breakpoints.down("md")]: {
              fontSize: 18,
            },
            ...(typographyProps?.sx || {}),
          }}
          pb={{ xs: 6, md: 12 }}
        >
          Frequently Asked questions
        </Typography>
        <Grid container>
          {faqs?.map(({ question, answers }, idx) => (
            <FaqsItem
              key={question}
              question={question}
              answer={answers}
              idx={idx}
              questionTypographyProps={questionTypographyProps}
              accordionProps={accordionProps}
            />
          ))}
        </Grid>
      </>
    </Section>
  );
};

export default memo(Faqs);
